<template>
  <transition name="slide-fade">
    <section
      v-if="showOverlay"
      v-scroll-lock="showOverlay"
      class="header__overlay"
    >
      <div class="container">
        <div class="overlay__wrapper h-padding-bottom-0 h-height-auto">
          <div class="h-padding-bottom-2">
            <div class="columns -align-horizontal">
              <div class="column is-ten is-twelve-tablet">
                <h2 class="heading h1 -align-center">
                  Your product vision. <strong>Our craft.</strong>
                </h2>
              </div>
            </div>
            <div
              class="
                columns
                -align-horizontal
                h-margin-bottom-5 h-hide-small-down
              "
            >
              <div class="column is-eight is-twelve-tablet">
                <p class="text -align-center">
                  Million dollar idea? We can help you execute on your product
                  vision from start to finish – plan, design, development and
                  deployment.
                </p>
                <p class="text -align-center">
                  <em
                    ><strong
                      >Delivering reliable product experiences.</strong
                    ></em
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="features">
            <div class="columns">
              <div class="column is-four is-twelve-small">
                <div
                  class="feature has-separator"
                  itemscope
                  itemtype="http://schema.org/Person"
                >
                  <img
                    :src="require('~/assets/images/lightbulb.svg')"
                    class="feature__icon"
                    alt="Icon of a lightbulb"
                    data-not-lazy="true"
                  />
                  <div class="feature__content">
                    <h3 class="feature__subtitle">New business</h3>
                    <h2 class="feature__title">
                      <span itemprop="givenName">Nick</span>
                      <span itemprop="familyName">Ciolpan</span>
                    </h2>
                    <p class="text">
                      <a
                        class="link"
                        href="mailto:nick@graffino.com"
                        itemprop="email"
                        >nick [at] graffino.com</a
                      >
                      <br />
                      <a
                        class="link"
                        href="tel:+40754372780"
                        itemprop="telephone"
                        >+40 [754] 372-780</a
                      >
                    </p>
                  </div>
                </div>
              </div>
              <hr class="divider h-show-small-down" />
              <div class="column is-four h-hide-small-down">
                <div
                  class="feature has-separator"
                  itemscope
                  itemtype="http://schema.org/PostalAddress"
                >
                  <img
                    :src="require('~/assets/images/coffee.svg')"
                    class="feature__icon"
                    alt="Icon of a machine"
                    data-not-lazy="true"
                  />
                  <div class="feature__content">
                    <h3 class="feature__subtitle">
                      <time>{{ currentTime }}</time
                      >. Coffee?
                    </h3>
                    <h2 class="feature__title">
                      <span itemprop="addressLocality">Sibiu</span>,
                      <span itemprop="addressCountry">Romania</span>
                    </h2>
                    <p class="text">
                      <a
                        class="link"
                        href="mailto:hello@graffino.com"
                        itemprop="email"
                        >hello [at] graffino.com</a
                      >
                      <a
                        class="link"
                        href="https://goo.gl/maps/12NjhejA8PUQkYhb8"
                        target="_blank"
                        itemtype="http://schema.org/Place"
                        itemprop="location"
                        >Calea Dumbravii 67</a
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="column is-four is-twelve-small">
                <div
                  class="feature"
                  itemscope
                  itemtype="http://schema.org/JobPosting"
                >
                  <img
                    :src="require('~/assets/images/code.svg')"
                    class="feature__icon"
                    alt="Icon of a HTML tag"
                    data-not-lazy="true"
                  />
                  <div class="feature__content">
                    <h3 class="feature__subtitle">Careers</h3>
                    <h2 class="feature__title">
                      Join
                      <span itemprop="hiringOrganization">Graffino</span>
                    </h2>
                    <p class="text">
                      Check out our new job postings on
                      <a
                        class="link -color-gray -type-inverted"
                        href="/careers"
                        target="_blank"
                        itemprop="location"
                        >LinkedIn</a
                      >.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import dayjs from 'dayjs'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'HeaderOverlay',
  props: {
    showOverlay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('time', ['now']),
    currentTime() {
      return dayjs(this.now).format('hh:mm A')
    },
  },
  mounted() {
    this.start()
  },
  methods: {
    ...mapActions({
      start: 'time/start',
    }),
  },
}
</script>
