<template>
  <footer class="footer">
    <div class="container">
      <div class="columns h-display-flex h-flex-wrap-wrap">
        <div class="column is-four is-six-medium is-twelve-small">
          <div class="footer__info">
            <h3 class="h-no-margin">Stay connected for</h3>
            <h2>Frequent updates</h2>
          </div>
          <div class="footer__box">
            <ul class="footer__links">
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-social-x" />
                </svg>
                <a
                  class="link -color-gray"
                  href="https://x.com/graffino"
                  target="_blank"
                >
                  X.com / Graffino
                </a>
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-social-github" />
                </svg>
                <a
                  class="link -color-gray"
                  href="https://github.com/Graffino"
                  target="_blank"
                >
                  Github.com / Graffino
                </a>
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-social-linkedin" />
                </svg>
                <a
                  class="link -color-gray"
                  href="https://www.linkedin.com/company/graffino"
                  target="_blank"
                >
                  LinkedIn.com / Graffino
                </a>
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-social-medium" />
                </svg>
                <a
                  class="link -color-gray"
                  href="https://medium.com/by-graffino"
                  target="_blank"
                >
                  Medium.com / Graffino
                </a>
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-social-instagram" />
                </svg>
                <a
                  class="link -color-gray"
                  href="https://www.instagram.com/graffinosoftware/"
                  target="_blank"
                >
                  Instagram.com / GraffinoSoftware
                </a>
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-social-facebook" />
                </svg>
                <a
                  class="link -color-gray"
                  href="https://facebook.com/graffino"
                  target="_blank"
                >
                  Facebook.com / Graffino
                </a>
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-social-dribbble" />
                </svg>
                <a
                  class="link -color-gray"
                  href="https://dribbble.com/graffino"
                  target="_blank"
                >
                  Dribbble.com / Graffino
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="column is-three is-six-medium is-twelve-small">
          <div class="footer__info">
            <h3 class="h-no-margin">Build something with</h3>
            <h2>Our services</h2>
          </div>
          <div class="footer__box">
            <ul class="footer__links">
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-frontend-icon" />
                </svg>
                <nuxt-link class="link -color-gray" to="/frontend-development"
                  >Frontend Development</nuxt-link
                >
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-web-development-icon" />
                </svg>
                <nuxt-link class="link -color-gray" to="/web-development"
                  >Software Development</nuxt-link
                >
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="18" height="18">
                  <use xlink:href="#icon-web-design" />
                </svg>
                <nuxt-link class="link -color-gray" to="/web-design"
                  >Web Design</nuxt-link
                >
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-software-outsourcing" />
                </svg>
                <nuxt-link
                  class="link -color-gray"
                  to="/software-development-outsourcing"
                  >Software Outsourcing</nuxt-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="column is-three is-six-medium is-twelve-small">
          <div class="footer__info">
            <h3 class="h-no-margin">Stay fresh with our</h3>
            <h2>Latest Bits</h2>
          </div>
          <div class="footer__box">
            <ul class="footer__links">
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-knowledge" />
                </svg>
                <a class="link -color-gray" href="/til" target="_blank">
                  Today I Learned
                </a>
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-radar" />
                </svg>
                <a class="link -color-gray" href="/techstack" target="_blank"
                  >Tech Stack</a
                >
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-cookie" />
                </svg>
                <nuxt-link class="link -color-gray" to="/apps"
                  >Free Treats</nuxt-link
                >
              </li>
              <li class="footer__link">
                <svg class="footer__icon" width="20" height="20">
                  <use xlink:href="#icon-case-studies" />
                </svg>
                <nuxt-link class="link -color-gray" to="/case-studies"
                  >Case Studies</nuxt-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="column is-two is-six-medium is-twelve-small">
          <div class="footer__info h-hide-small-down">
            <div class="footer__placeholder" />
          </div>
          <div class="footer__box is-last-child h-align-left-medium">
            <ul class="footer__links">
              <li class="footer__link">
                <a v-smooth-scroll href="/" class="link -color-gray">
                  &copy;&nbsp;{{ currentYear }}&nbsp;Graffino
                </a>
              </li>
              <li class="footer__link -line-height-1">
                <a
                  class="link -color-gray -font-size-3"
                  href="/privacy-policy"
                  target="_blank"
                  >Privacy Policy</a
                >
              </li>
              <li class="footer__link -line-height-1">
                <a
                  class="link -color-gray -font-size-3"
                  href="/terms-of-service"
                  target="_blank"
                  >Terms of Service</a
                >
              </li>
              <li class="footer__link -line-height-1">
                <a
                  class="link -color-gray -font-size-3"
                  href="/gdpr"
                  target="_blank"
                  >GDPR</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      currentYear: null,
    }
  },
  mounted() {
    this.getCurrentYear()
  },
  methods: {
    getCurrentYear() {
      const date = new Date()
      let year = date.getFullYear()
      this.currentYear = year
    },
  },
}
</script>
